import { SchemaTransformerArgs } from '@web-config-app/core';
import jsonLogic from 'json-logic-js';

/**
 * This function handles the `disabledIf` type of `x-entity-conditional` annotation
 * @param schema - the current schema slice
 * @param data - the form data
 * @returns an annotatedJsonSchema with the readOnly flag set to true (which will disable a given control), if the json logic rule evaluates to true,
 * otherwise returns the schema as is
 */
export const evaluateDisabledIfCondition = (
  schema: SchemaTransformerArgs['schema'],
  data?: SchemaTransformerArgs['data'],
) => {
  const disableIfCondition = schema?.['x-entity-conditional']?.conditions?.find(
    (condition) => condition?.type === 'disabledIf',
  );
  if (!disableIfCondition) return schema;
  const { rules } = disableIfCondition;

  // TODO: Future enhancement (or not): this probably also need to traverse an object and recursively disable all child properties
  const computedSchema = jsonLogic.apply(rules, data)
    ? {
        ...schema,
        readOnly: true,
      }
    : schema;
  return computedSchema;
};
