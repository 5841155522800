import * as React from 'react';
import { Box, StackLayout, TextAction } from '@leagueplatform/genesis-core';
import { NavLink } from '@leagueplatform/routing';
import { composePaths } from '@jsonforms/core';
import { createDefaultValueForSchema } from '../../../utils/create-default-value-for-schema/create-default-value-for-schema';
import {
  ArrayControl,
  EntityFormArrayControlProps,
  ComplexObjectArrayControlItem,
} from '../../../types/controls';
import { EntityFormArrayControl } from '../../entity-form-array-control/entity-form-array-control.component';
import { ArrayAddButton } from '../../array-add-button/array-add-button.component';
import { CombinatorArrayAddActionMenu } from '../../combinator-array-add-action-menu/combinator-array-add-action-menu.component';
import { NumberIcon } from '../../number-icon/number-icon.component';
import { ArrayActionButtons } from '../../array-action-buttons/array-action-buttons.component';
import { CompoundComplexHeading } from '../../compound-complex-heading/compound-complex-heading.component';
import { EmptyArrayState } from '../../empty-array-state/empty-array-state.component';
import { addCombinatorOptionToArray } from '../../../utils/add-combinator-option-to-array/add-combinator-option-to-array.util';
import { useArrayControlCombinatorHelpers } from '../../../hooks/use-array-control-combinator-helpers/use-array-control-combinator-helpers.hook';
import { useObjectCardLink } from '../../../hooks/use-object-card-link/use-object-card-link';
import { getDataPointValue } from '../../../utils/get-data-point-value/get-data-point-value';

/**
 * This control renders for Arrays of complex objects. A complex object is an object that has properties that are also
 * objects. Another way to describe this would be to say the object has nested objects inside of it.
 */

export const ArrayOfComplexObjectsControlContents = ({
  path,
  schema,
  data,
  id,
  banner,
  hint,
  label,
  addItem,
  removeItems,
  moveDown,
  moveUp,
  arrayAddLabel,
  itemLabel: defaultItemLabel,
  itemLabelPropertyRef,
  errors,
  combinatorProperties,
}: EntityFormArrayControlProps) => {
  const handleRemoveItem = React.useCallback(
    (itemPath: string, index: any) => () => {
      removeItems(itemPath, [index])();
    },
    [removeItems],
  );

  const handleOnMoveUp = React.useCallback(
    (itemPath: string, index: number) => () => {
      moveUp(itemPath, index)();
    },
    [moveUp],
  );

  const handleOnMoveDown = React.useCallback(
    (itemPath: string, index: number) => () => {
      moveDown(itemPath, index)();
    },
    [moveDown],
  );

  const handleCreateDefaultValue = React.useCallback(
    () => createDefaultValueForSchema(schema) ?? {},
    [schema],
  );

  const {
    combinatorActionMenuItems,
    getSelectedSubSchema,
    getLabelForSelectedSubSchema,
  } = useArrayControlCombinatorHelpers(combinatorProperties);

  const { getLinkForObjectCard } = useObjectCardLink();

  const itemsWithLabels: ComplexObjectArrayControlItem[] = React.useMemo(() => {
    const items = Array.isArray(data) ? data : [];
    return items.map((item: any, index: number) => {
      const itemLabelDataValue = itemLabelPropertyRef
        ? getDataPointValue(item, itemLabelPropertyRef)
        : null;

      const itemNumber = index + 1;
      const itemLabel =
        itemLabelDataValue?.length > 0 ? itemLabelDataValue : defaultItemLabel;
      const isLastItem = items.length === itemNumber;
      const uniqueId = `${id}-${index}`;
      const selectedSubSchema = getSelectedSubSchema(item);
      const subSchemaLabel = getLabelForSelectedSubSchema(selectedSubSchema);
      const childPath = composePaths(path, `${index}`);
      const to = getLinkForObjectCard(childPath);

      return {
        itemLabel: subSchemaLabel ?? itemLabel,
        isLastItem,
        itemNumber,
        uniqueId,
        to,
        ...item,
      };
    });
  }, [
    data,
    defaultItemLabel,
    itemLabelPropertyRef,
    id,
    getSelectedSubSchema,
    getLabelForSelectedSubSchema,
    path,
    getLinkForObjectCard,
  ]);

  const arrayItemsEmpty = itemsWithLabels?.length === 0;

  return (
    <>
      <CompoundComplexHeading
        label={label}
        banner={banner}
        hint={hint}
        error={errors}
      />
      {arrayItemsEmpty ? (
        <EmptyArrayState item={label} />
      ) : (
        <StackLayout
          as="ol"
          aria-label={label}
          css={{
            marginTop: '$threeQuarters',
            border: '$borderWidths$thin solid $onSurfaceBorderSubdued',
            borderRadius: '$medium',
            paddingInlineStart: '$none',
          }}
        >
          {itemsWithLabels.map(
            (
              { itemLabel, itemNumber, isLastItem, uniqueId, to },
              index: number,
            ) => (
              <StackLayout
                orientation="horizontal"
                verticalAlignment="center"
                spacing="$one"
                as="li"
                key={uniqueId}
                css={{
                  width: '100%',
                  padding: '$one',
                  marginTop: 0,
                  borderBottom: `${
                    isLastItem
                      ? 'none'
                      : '$borderWidths$thin solid $onSurfaceBorderSubdued'
                  }`,
                  '&:first-of-type': {
                    borderRadius: '$medium $medium 0 0',
                  },
                  '&:last-of-type': {
                    borderRadius: '0 0 $medium $medium',
                  },
                  '&:first-of-type:last-of-type': {
                    borderRadius: '$medium',
                  },
                  backgroundColor: '$surfaceBackgroundPrimary',
                }}
              >
                <Box css={{ flexGrow: 0, flexShrink: 0 }}>
                  <NumberIcon number={itemNumber} />
                </Box>

                <Box css={{ flexGrow: 1 }}>
                  <TextAction as={NavLink} to={to}>
                    {itemLabel}
                  </TextAction>
                </Box>
                <Box css={{ flexGrow: 0, flexShrink: 0 }}>
                  <ArrayActionButtons
                    showDeleteModal
                    onDelete={handleRemoveItem(path, index)}
                    onMoveDown={handleOnMoveDown(path, index)}
                    onMoveUp={handleOnMoveUp(path, index)}
                    itemLabel={itemLabel as string}
                    itemIndex={index}
                    arrayLength={itemsWithLabels.length}
                  />
                </Box>
              </StackLayout>
            ),
          )}
        </StackLayout>
      )}
      {combinatorActionMenuItems?.length ? (
        <CombinatorArrayAddActionMenu
          buttonLabel={arrayAddLabel}
          menuItems={combinatorActionMenuItems}
          onSelectItem={(itemId) =>
            addCombinatorOptionToArray({
              value: itemId,
              combinatorProperties,
              path,
              addItem,
            })
          }
        />
      ) : (
        <ArrayAddButton
          addItem={addItem}
          path={path}
          createDefaultValue={handleCreateDefaultValue}
          addButtonLabel={arrayAddLabel}
        />
      )}
    </>
  );
};

const renderArrayControl = (formContentsProps: EntityFormArrayControlProps) => (
  <ArrayOfComplexObjectsControlContents {...formContentsProps} />
);

export const ArrayOfComplexObjectsControl: ArrayControl = (props) => (
  <EntityFormArrayControl {...props} renderControl={renderArrayControl} />
);
