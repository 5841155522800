import {
  AnnotatedJsonSchema,
  SchemaTransformerArgs,
  SchemaTransformer,
} from '@web-config-app/core';
import { evaluateIncludeIfCondition } from '../../evaluate-include-if-conditional/evaluate-include-if-conditional';
import { evaluateDisabledIfCondition } from '../../evaluate-disable-if-conditional/evaluate-disable-if-conditional';
import { getSchemaWithMutatedProperties } from '../../get-schema-with-mutated-properties/get-schema-with-mutated-properties';

/**
 * This transformer function handles the x-entity-conditional annotation
 * @param schema - the current schema slice
 * @param data - the form data
 * @returns an annotatedJsonSchema
 */

export const applyConditionalLogic: SchemaTransformer = ({
  schema,
  data,
}: SchemaTransformerArgs) =>
  getSchemaWithMutatedProperties({
    schema,
    filterProperties: (propertySchema: AnnotatedJsonSchema) =>
      evaluateIncludeIfCondition(propertySchema, data),
    mutateProperties: (propertySchema: AnnotatedJsonSchema) =>
      evaluateDisabledIfCondition(propertySchema, data),
  });
