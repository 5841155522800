import { SchemaTransformerArgs } from '@web-config-app/core';
import jsonLogic from 'json-logic-js';

/**
 * The evaluateIncludeIfCondition function is a part of the x-entity-conditional annotation used to evaluate the includeIf condition.
 *
 * @param schema - the schema that implements or extends the {@link AnnotatedJsonSchema} interface
 * @param data - data that corresponds to the schema
 * @returns true or false based on the json logic rule applied
 */
export const evaluateIncludeIfCondition = (
  schema: SchemaTransformerArgs['schema'],
  data: SchemaTransformerArgs['data'],
) => {
  const includeIfCondition = schema?.['x-entity-conditional']?.conditions?.find(
    (condition) => condition?.type === 'includeIf',
  );
  if (!includeIfCondition) return true;
  const { rules } = includeIfCondition;

  return jsonLogic.apply(rules, data);
};
